import { defineStore } from 'pinia';

export const useCookiesStore = defineStore(
  'cookies',
  () => {
    const chose = ref(false);
    const setChose = (value: boolean) => {
      chose.value = value;
    };

    return {
      chose,
      setChose,
    };
  },
  {
    persist: true,
  }
);
