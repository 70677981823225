<template>
  <footer class="relative mt-[100px]" :class="{ 'hidden sm:block': isChat }">
    <div class="bg-graies-1 pt-[105px] pb-4 sm:pb-6">
      <img src="~/assets/images/sahelp-logo.png" class="w-[136px] absolute inset-x-0 top-0 -translate-y-1/2 mx-auto" />
      <img src="~/assets/images/logo-sahelp.gif" class="absolute inset-x-0 top-20 -translate-y-1/2 mx-auto" />
      <div class="container">
        <ul
          class="flex flex-col sm:flex-row items-center text-base font-proxima text-white text-center justify-center sm:space-x-1"
        >
          <li>{{ $t(`Content Copyright © 2022-present. The Salvation Army. All Rights Reserved`) }}</li>
          <li class="sm:block hidden">|</li>
          <li>
            <a
              href="https://www.salvationarmyusa.org/usn/privacypolicy/"
              target="_blank"
              class="underline underline-offset-4"
              >{{ $t(`Privacy Policy`) }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="bg-black py-4">
      <div class="container flex justify-center">
        <a href="https://www.dataparadigm.com/" target="_blank">
          <div class="hidden sm:flex flex-shrink-0 items-center font-proxima text-white text-sm">
            <img class="w-auto mr-4" src="~/assets/images/dpi.png" alt="Your Company" />
            <span class="hidden sm:flex"> {{ $t(`Powered by Data Paradigm, Inc.`) }} </span>
          </div>
        </a>
        <div class="text-gray-600 text-[10px] text-right absolute bottom-1 right-2">{{ $t('Version') }} {{ version }}</div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
const isChat = ref(useRoute().path.includes('-chat'));

const config = useRuntimeConfig();

const version = ref(config.public.clientVersion);
</script>
