import { defineStore } from 'pinia';

export const useVersionStore = defineStore('version', () => {
  const appVersion = ref('');
  const setAppVersion = (version: string) => {
    appVersion.value = version;
  };

  const getVersion = () => {
    return $fetch<{
      success: boolean;
      data: {
        id: string;
        application: string;
        latestAvailableVersion: string;
        latestRequiredVersion: string;
        createdAt: string;
      };
    }>(`/versions/latest`, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  return {
    appVersion,
    getVersion,
    setAppVersion,
  };
});
