<template>
  <div
    v-if="!cookieStore.chose"
    class="fixed inset-x-0 top-0 z-50 bg-black w-full bg-gradient-to-r from-[#050505a3] via-[#536d7ea6] to-[#050505a3] text-white font-proxima p-2 grid grid-cols-12 gap-4"
  >
    <div class="col-span-12 lg:col-span-9">
      {{
        $t(
          `We use cookies to enhance performance and analyze our site. We do not share information with any third parties except what is necessary for the processing of your assistance request(s) as described herein. Please select your privacy settings below:`
        )
      }}
      <NuxtLink to="/privacy-policy" class="text-orange-400 hover:underline hover:text-orange-500 cursor-pointer">{{
        $t(`Privacy policy`)
      }}</NuxtLink>
    </div>
    <div class="col-span-12 lg:col-span-3 flex">
      <button @click="allowCookies()" class="border border-green-400 text-white px-4 py-0.5 rounded-md mx-3">
        {{ $t(`Allow Cookies`) }}
      </button>
      <button @click="allowCookies()" class="border border-orange-400 text-white px-4 py-0.5 rounded-md">
        {{ $t(`Disallow Cookies`) }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCookiesStore } from '~/composables/stores/cookies';

const cookieStore = useCookiesStore();

const allowCookies = () => {
  cookieStore.setChose(true);
};
</script>
